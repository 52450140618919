.c-emoji {
  font-size: 30px;
  vertical-align: middle;
  line-height: initial;
  display: inline-block;
  line-height: 0;

  @media #{$bp-desk} {
    &--in-margin {
      vertical-align: middle;
      margin: 0px 11px 0 -40px;
    }
  }

  &--large {
    font-size: 40px;
    vertical-align: baseline;
    margin: 0 10px;

    @media #{$bp-hand} {
      font-size: 60px;
    }
  }

  &--small {
    font-size: 24px;
    margin: 0 10px 0 20px;
  }
}
