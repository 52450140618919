.c-overlay-hud {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  align-items: center;

  @include theme-var(background-color, c-background-accent2-dark);
}

.c-overlay-hud__col-primary {
  flex: 1;
  padding: 1vh 0 1vh 3vw;
  @media #{$bp-lap} { padding: 1vh 0 1vh 1vw; }
  @media #{$bp-desk} { padding: 1vh 0 1vh 0.5vw; }
}

.c-overlay-hud__col {
  padding: 0 3vw;
  padding: 1vh 2vw;
  @include theme-var(border-left-color, c-background-primary-transparent);
  @media #{$bp-lap} { padding: 1vh 1vw; }
  @media #{$bp-desk} { padding: 1vh 0.5vw; }
}

.c-overlay-hud__col--hide-mobile {
  display: none;
  @media #{$bp-lap} { display: block; }
}
