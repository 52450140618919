$-border: 4px;
$-knob-size: 14px;
$-knob-border-size: 2px;
$-knob-pad: 6px;
$-toggle-width: 150px;
$-radius: 100000px;
$-height: 38px;

.c-toggle {
  cursor: pointer;
  outline: 0;
  position: relative;
  border-radius: $border-radius;
  height: $-height + $-border;
  width: $-toggle-width;
  padding-left: 0;
  padding-right: $-knob-size;
  font: 400 16px / 1 $ff-primary;
  @include theme-var(background-color, c-background-accent);
  @include theme-var(color, c-foreground-medium);
  @include theme-var(border-color, c-background-primary);

  @include button-focus;

  &::after {
    content: "";
    transition: right 0.2s ease, padding 0.2s ease;
    border-radius: 50%;
    height: $-knob-size;
    width: $-knob-size;
    position: absolute;
    right: $-border + $-knob-pad;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    border: solid $-knob-border-size;
    @include theme-var(border-color, c-foreground-medium);
  }
}

.c-toggle--active {
  padding-right: 0;
  padding-left: $-knob-size;

  &::after {
    right: calc(100% - #{$-knob-size} - #{$-border} - #{$-knob-border-size} - #{$-knob-pad});
  }
}
