//----------------- Breakpoints & Thangz
$w-hand: "601px";
$w-lap: "901px";
$w-desk: "1101px";
$w-wide: "1251px";

$bp-touch: "(hover: none) and (pointer: coarse)";
$bp-hand: "(min-width: #{$w-hand})";
$bp-lap: "(min-width: #{$w-lap})";
$bp-lap-to-desk: "(min-width: #{$w-lap}) and (max-width: #{$w-desk})";
$bp-desk: "(min-width: #{$w-desk})";
$bp-desk-to-wide: "(min-width: #{$w-desk}) and (max-width: #{$w-wide})";
$bp-wide: "(min-width: #{$w-wide})";

$border-radius: 10px;
$font-size: 20px;
$page-max-width: 1400px;

//----------------- Z-Index
$z-1: 10;
$z-2: 20;
$z-3: 30;
$z-4: 40;

//----------------- Spacing
$s-horizontal-padding: 2.8vw;
$s-xtiny: 6px;
$s-tiny: 12px;
$s-small: 24px;
$s-regular: 36px;
$s-large: 54px;
$s-xlarge: 72px;

//----------------- Theme Colors
$colors-light: (
  background-blend-mode: luminosity,
  c-background-primary-animation-alternate: #fdebd1,
  c-background-primary: #e1fdd1,
  c-background-primary-transparent: rgba(#fcfdd1, 0.9),
  c-background-accent: #fff9f0,
  c-background-accent2: #f3f0ff,
  c-background-accent2-dark: #d3e9fc,
  c-primary: #6a5ef5,
  c-primary-200: rgba(#6a5ef5, 0.5),

  // Neutrals
  c-foreground-xlight: #f8fdff,
  c-foreground-light: #4f4744,
  c-foreground-medium: #343f48,
  c-foreground-focal: #0f150a,
  c-foreground-transparent: rgba(#343f48, 0.1),

  c-system: #171717,
  c-border: #e9dbdb,
);

$colors-dark: (
  c-background-primary: #131212,
  c-background-primary-transparent: rgba(#32293e, 0.2),
  // c-background-primary: #2e2e3e,
  c-background-accent: #1d1b1b,
  // c-background-accent: #25222e,
  c-background-accent2: #14191f,
  c-background-accent2-dark: #2b2335,

  c-primary: #15c9ae,
  c-primary-200: rgba(#15c9ae, 0.5),

  // Neutrals
  c-foreground-xlight: #f8fdff,
  c-foreground-light: #ffe5d3,
  c-foreground-medium: #f3d8b7,
  c-foreground-focal: #f8fdff,
  c-foreground-transparent: rgba(#cab5e3, 0.07),

  c-system: #171717,
  c-border: #413131,
);

$DEFAULT-THEME: $colors-light;

//----------------- Type Mixins
$ff-primary: "Graphik", sans-serif;
$ff-secondary: "Louize", Georgia, "Times New Roman", serif;
$ff-display: "Louize Display", Georgia, "Times New Roman", serif;
$ff-monospace: monospace;
