.c-link {
  // transition: all 0.1s ease-in-out;
  @include theme-var(color, c-foreground-medium);
  position: relative;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // Squeezing this element just little closer to prevent the x-padding from making the c-link appear misaligned
  margin-right: -($s-xtiny / 2);
  margin-left: -($s-xtiny / 2);
  padding-left: $s-xtiny;
  padding-right: $s-xtiny;
  border-radius: $border-radius;

  &::before {
    bottom: -4px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.1s ease-in-out;
    width: 100%;
    @include theme-var(background, c-primary, 0.04);
  }

  &[href]:focus {
    outline: 2px solid;
    @include theme-var(outline-color, c-primary);
    &::before {
      @include theme-var(background, c-primary, 0.5);
      transform: translateY(-3px);
    }
  }

  &[href]:active,
  &[href]:hover {
    @include theme-var(background, c-primary);
    @include theme-var(color, c-foreground-xlight);

    &::before {
      @include theme-var(background, c-primary, 0.5);
      transform: translateY(-3px);
    }
  }

  &--arrow-remote {
    cursor: ne-resize;
  }

  &--arrow-r {
    cursor: e-resize;
  }

  &--arrow-d {
    cursor: s-resize;
  }

  &--arrow-d {
    cursor: s-resize;
  }

  &--active {
    text-decoration: line-through;
    pointer-events: none;
    cursor: not-allowed;

    &::before { background: transparent; }
  }

  &--focal {
    font-size: 16px;

    &::after {
      content: ' \2192';
      margin: 0 0 0 2px;
      transition: all 0.1s ease-in-out;
      @include theme-var(background, c-primary, 0.04);
    }

    &[href]:hover::after {
      @include theme-var(background, c-primary);
      margin: 0 0 0 6px;
    }
  }

  &--on-dark {
    color: c-foreground-xlight;

    &.c-link--active {
      background: rgba(#ffffff, 0.2);
    }
  }
}
