.c-wrapper {
  margin: 0;
  padding: 60px 6vw;
  position: relative;
  box-sizing: border-box;

  @include theme-var(background-color, c-background-primary);

  @media #{$bp-lap} { padding: 80px 6vw; }
  @media #{$bp-desk} { padding: 140px 8vw; }
  @media #{$bp-wide} { padding: 140px 24vw; }

  &--header {
    padding-top: 120px;

    @media #{$bp-lap} {
      padding-bottom: 130px;
      padding-top: 160px;
    }
  }

  &--full {
    overflow-x: hidden;
    overflow-y: visible;

    @media #{$bp-desk} {
      padding-left: 9%;
      padding-right: 9%;
    }
  }

  &--table-bleed {
    overflow-x: hidden;
    overflow-y: visible;

    @media #{$bp-lap} { padding-left: calc(6vw - $s-xlarge); padding-right: calc(6vw - $s-xlarge); }
    @media #{$bp-desk} { padding-left: calc(8vw - $s-xlarge); padding-right: calc(8vw - $s-xlarge); }
    @media #{$bp-wide} { padding-left: calc(24vw - $s-xlarge); padding-right: calc(24vw - $s-xlarge); }
  }

  &--slim {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  &--slimmest {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  &--flush {
    padding-bottom: 0;
    padding-top: 0;
  }

  &--white { background: #ffffff; }
  &--sepia { @include theme-var(background-color, c-background-primary); }
  &--accent { @include theme-var(background-color, c-background-accent); }

  &__video-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.2;
    width: 100%;
    margin: -25% 0 0 0;
  }
}
