.c-overlay-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 1000;
  box-sizing: border-box;

  @include theme-var(background-color, c-background-accent2);
  padding: 13vh 7vw 0;
  overflow-y: scroll;

  top: 100%;
  // height: 0vh;
  transition:
    opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    height cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    top cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
  ;

  @media #{$bp-lap} { padding: 20vh 20vw; }
  @media #{$bp-wide} { padding: 20vh 32vw; }
}

.c-overlay-modal--active {
  top: 0;
}

$button_height_base: 54px;
.c-overlay-modal__button {
  width: 100%;
  height: $button_height_base;
  z-index: 2000;
  cursor: n-resize;
  border-radius: $border-radius;
  text-align: left;
  padding: 0 $s-tiny;

  transition:
    // height cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    backgound-color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
  ;

  @include theme-var(background-color, c-background-accent2-dark);
  @include theme-var(color, c-foreground-medium);
  @include button-focus;

  font: 400 20px / 1.65 $ff-primary;
  @media #{$bp-hand} { font: 400 20px / 1.65 $ff-primary; }
  @media #{$bp-desk} { font: 400 18px / 1.55 $ff-primary; }

  svg {
    vertical-align: -5px;
    margin-right: $s-xtiny;
    path {
      @include theme-var(fill, c-foreground-medium);
    }
  }
}

.c-overlay-modal__button--active {
  // height: $button_height_base + 8px;
  cursor: s-resize;
}

.c-overlay-modal__button:hover {
  @include theme-var(color, c-foreground-focal);
  svg path {
    @include theme-var(fill, c-foreground-focal);
  }
  // height: $button_height_base + 4px;
  // font-weight: 500;
}
