.u-highlight-parent {}
.u-highlight-parent--active {
  .u-highlight {
    // font-size: 110%;
    // line-height: 100%;
    // @include theme-var(background-color, c-background-accent2-dark);
    @media #{$bp-lap} {
      @include theme-var(color, c-foreground-light);
    }
  }
}
