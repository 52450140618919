@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Graphik-Light-Web.woff") format("woff");
      }

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Graphik-Semibold-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Graphik-Bold-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Louize-Regular-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Louize-RegularItalic-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Louize-Regular-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Louize-Bold-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Louize-Medium-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Louize-DisplayRegular-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize Display";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Louize-DisplayRegularItalic-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Louize-DisplayMedium-Web.woff") format("woff");
}

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Louize-DisplayBold-Web.woff") format("woff");
}
