.u-animate-in {
  @keyframes fade-in {
    0% {
      transform: translateY(8px);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in;
}

.u-animate-wave {
  @keyframes wave {
    0% {
      transform: rotate(0) scale(1);
    }
    10% {
      transform: rotate(10deg) scale(1.2);
    }
    20% {
      transform: rotate(0) scale(1.2);
    }
    30% {
      transform: rotate(10deg) scale(1.2);
    }
    40% {
      transform: rotate(0) scale(1);
    }
    100% {
      transform: rotate(0) scale(1);
    }
  }

  transform: rotate(0);
  animation: 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) wave;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  // @media #{$bp-hand} { animation-iteration-count: 3; }

  // :hover > & {
  //   animation: 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) wave;
  //   animation-delay: 0;
  //   animation-iteration-count: infinite;
  // }
}
