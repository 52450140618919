.u-highlight-parent.u-highlight-parent--active .c-paragraph {
  @media #{$bp-lap} {
    @include theme-var(color, c-foreground-transparent, true);
  }
}

.c-paragraph {
  font: 400 24px / 1.65 $ff-secondary;
  margin: 0 0 25px;
  max-width: 680px;
  padding: 0;

  @include theme-var(color, c-foreground-light);

  @media #{$bp-hand} { font: 400 28px / 1.65 $ff-secondary; }
  @media #{$bp-lap} { font: 400 24px / 1.65 $ff-secondary; }

  br {
    display: none;
    @media #{$bp-lap} { display: inline; }
  }

  &--center {
    text-align: center;
  }

  &--smallest {
    font-size: 12px;
    margin: 0 0 10px;
  }

  &--small {
    font-size: 14px;
    margin: 0 0 10px;
  }

  &--medium {
    @include theme-var(color, c-foreground-medium);
    max-width: 570px;
    font: 400 18px / 1.65 $ff-secondary;
    @media #{$bp-hand} { font: 400 18px / 1.65 $ff-secondary; }
    @media #{$bp-desk} { font: 400 20px / 1.55 $ff-secondary; }
  }

  &--sans-large {
    font-family: $ff-primary;
    color: #727779;
    font-size: 20px;
    @media #{$bp-lap} { font: 400 18px / 1.65 $ff-primary; }
  }

  &--sans-medium {
    font-family: $ff-primary;
    @include theme-var(color, c-foreground-medium);
    font-size: 16px;
    max-width: 570px;
  }

  &--sans-small {
    @include theme-var(color, c-foreground-focal);
    font-family: $ff-primary;
    font-size: 14px;
    margin: 0 0 10px;
  }

  &--sans-smallest {
    font-family: $ff-primary;
    font-size: 12px;
    margin: 0 0 10px;
  }

  &--quote {
    max-width: none;
    font: 500 18px / 1.9 $ff-secondary;
    font-style: italic;

    &::before {
      content: open-quote;
      font: 500 40px / 0 $ff-secondary;
      margin: 0px 7px 0 -25px;
      opacity: 0.3;
    }
  }

  &--large {
    font: 400 28px / 1.4 $ff-display;
    max-width: 980px;
    margin: 0 0 45px;

    @include theme-var(color, c-foreground-light);

    @media #{$bp-lap} { font: 400 34px / 1.4 $ff-display; }
    @media #{$bp-desk} { font: 400 44px / 1.4 $ff-display; }
  }

  &--xlarge {
    font: 500 37px / 1.25 $ff-display;
    max-width: none;
    margin: 0 0 52px;

    @include theme-var(color, c-foreground-focal);

    @media #{$bp-hand} { font: 500 44px / 1.25 $ff-display; }
    @media #{$bp-lap} { font: 500 60px / 1.25 $ff-display; }
  }

  &--on-dark {
    color: white;
    opacity: 0.6;
  }
}
