.c-browser {
  $top-height: 20px;
  $border-radius: 4px;

  background: #cbd3d8;
  border-radius: $border-radius;
  overflow: hidden;
  padding: $top-height 0 0;
  position: relative;
  box-shadow: 0 4px 18px -4px rgba(#000000, 0.15);
  max-height: 380px;
  // box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.04), 0 18px 30px -18px rgba(0, 0, 0, 0.02), 0 0px 10px 1px rgba(0, 0, 0, 0.06);

  &::before {
    background: linear-gradient(to bottom, #EBF2F6, #e3ebf0);
    border-radius: $border-radius $border-radius 0 0;
    content: "";
    height: 4px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: $top-height;
    z-index: +1;
    border: 1px solid #D3E5EF;
    box-shadow: inset 0 1px 0 0 rgba(#ffffff, 0.5);
  }

  &::after {
    border: 1px solid rgba(#bddef0, 0.8);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    content: "";
    height: 8px;
    position: absolute;
    top: 6px;
    z-index: +1;
    left: 6px;
    right: 6px;
    margin: 0 auto;
  }

  &__content {
    width: 100%;
    box-sizing: border-box;

    // &::after {
    //   background: transparent linear-gradient(to bottom, rgba(#ffffff, 0), rgba(#ffffff, 0.1), rgba(#ffffff, 0.4));
    //   content: "";
    //   height: 40px;
    //   left: 0;
    //   right: 0;
    //   position: absolute;
    //   bottom: 0;
    // }
  }

  &--medium {
    height: 400px;
  }
}
