@keyframes avatar-animate1 {
  0% {
    // transform: scale(2) rotate(0deg);
    // transform: rotate(8deg) scale(1.3);
    transform: rotate(-3deg) scale(1.05);
    @include theme-var(background-color, c-primary);
  }
  20% {
    // transform: scale(1) rotate(360deg);
    // @include theme-var(background-color, c-background-accent2-dark);
    transform: rotate(8deg) scale(1.3);
    background-color: #00ffd9;
  }
  60% {
    // transform: scale(2) rotate(0deg);
    transform: rotate(-3deg) scale(1.05);
    @include theme-var(background-color, c-primary);
  }
  100% {
    // transform: scale(2) rotate(0deg);
    transform: rotate(-3deg) scale(1.05);
    @include theme-var(background-color, c-primary);
  }
}

@keyframes avatar-animate2 {
  0% {
    // transform: scale(2) rotate(0deg);
    // transform: rotate(8deg) scale(1.3);
    transform: rotate(6deg) scale(1.1);
    @include theme-var(background-color, c-primary);
  }
  20% {
    // transform: scale(1) rotate(360deg);
    // @include theme-var(background-color, c-background-accent2-dark);
    transform: rotate(-8deg) scale(1.1);
    background-color: #00ffd9;
  }
  60% {
    // transform: scale(2) rotate(0deg);
    transform: rotate(6deg) scale(1.1);
    @include theme-var(background-color, c-primary);
  }
  100% {
    // transform: scale(2) rotate(0deg);
    transform: rotate(6deg) scale(1.1);
    @include theme-var(background-color, c-primary);
  }
}

.c-avatar {
  position: relative;

  .c-avatar__element {
    @include theme-var(background-color, c-background-primary);
    // @include theme-var(background-color, c-primary);

    // background-image: url('../../images/avatar-2023-3.jpg');
    background-size: cover;
    background-position: center;
    // mask: url('../../images/avatar-mask-7.svg') center center/100% no-repeat;
    mask: url('../../images/avatar-mask-7.svg') center center/100% no-repeat;

    border-radius: $border-radius;
    z-index: $z-2;
    position: relative;
    overflow: hidden;

    border-radius: $border-radius;
    // margin: 0 0 $s-small 0;

    width: 100%;
    width: 100px;
    height: 100px;
    // padding: 20px;
    transform: rotate(-5deg);

    &::after {
      // @include theme-var(background-color, c-primary);

      background-size: cover;
      background-position: center;
      background-image: url('../../images/avatar-2024-1.jpg');
      mask: url('../../images/avatar-mask-7.svg') center center/100% no-repeat;

      // background-image: url('../../images/avatar-2023-3.jpg');
      // background-blend-mode: difference;
      // background-size: cover;
      // background-position: center;
      // animation: 16s linear avatar-animate1 infinite;
      // z-index: -1;

      content: '';
      top: 10px;
      left:  10px;
      right:  10px;
      bottom:  10px;
      position: absolute;
      transform: rotate(5deg);
      // opacity: 0.3;
    }

    // &::before {
    //   @include theme-var(background-color, c-primary);

    //   background-image: url('../../images/avatar-2023-3.jpg');
    //   background-blend-mode: color-dodge;
    //   background-size: cover;
    //   background-position: center;
    //   animation: 16s linear avatar-animate2 infinite;
    //   z-index: -1;

    //   content: '';
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   position: absolute;
    //   opacity: 0.4;
    // }

    // @media #{$bp-hand} {
    //   position: fixed;
    //   top: 0;
    //   right: 0;

    //   border-radius: unset;
    //   margin: 0;

    //   height: 120px;
    //   width: 120px;
    // }

    @media #{$bp-hand} {
      position: absolute;
      top: calc(-60px - 120px);
      right: -1vw;

      border-radius: $border-radius;

      height: 120px;
      width: 120px;
    }

    @media #{$bp-lap} {
      position: absolute;
      top: calc(-30px - 190px);
      right: -1vw;

      border-radius: $border-radius;

      height: 190px;
      width: 190px;
    }

    @media #{$bp-desk} {
      position: absolute;
      top: calc(-40px - 230px);
      right: -1vw;

      border-radius: $border-radius;

      height: 230px;
      width: 230px;
    }

    @media #{$bp-wide} {
      // top: calc(-100px - 230px);
      left: calc(-230px - 40px);
      right: unset;
    }
  }
}
